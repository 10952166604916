import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpotify } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

function Courses() {
  return (
    <div className='bg-[#E7D3B7] flex items-center justify-center p-5 md:p-10 roboto-mono-700'>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-5 w-full'>
      <div className='col-span-1 md:col-span-2 bg-[#F5E3CA] p-4 rounded-2xl object-shadow'>
  <h2 className='font-medium text-[#C2763F] text-2xl md:text-3xl p-4'>Main Course</h2>
  
  {/* Grid Layout for 2x2 structure */}
  <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
    
    {/* Phonetics Course */}
    <div className='p-4'>
      <h2 className='text-3xl md:text-4xl kg-red-hands font-semibold text-black'>Phonetics Course</h2>
      <p className='py-4 text-lg md:text-xl font-medium text-[#4B2E19]'>
        Our phonetics course is designed to equip you with the tools and techniques necessary to master English pronunciation and develop a clear American accent.
      </p>
    </div>
    
    {/* Learning Objectives */}
    <div className='p-4'>
      <h2 className='text-3xl md:text-4xl kg-red-hands font-semibold text-black'>Learning Objectives</h2>
      <ul className='list-disc text-[#5D2700] text-lg md:text-xl font-medium pl-6 py-4'>
        <li className='p-2'>Learn correct sound production and articulation</li>
        <li className='p-2'>Improve fluency and confidence</li>
        <li className='p-2'>Develop self-study skills</li>
        <li className='p-2'>Achieve accurate pronunciation and clear American accent</li>
      </ul>
    </div>

    {/* Course Details */}
    <div className='p-4'>
      <h2 className='text-3xl md:text-4xl kg-red-hands font-semibold text-black'>Course Details</h2>
      <ul className='text-[#4B2E19] text-xl md:text-2xl font-medium py-4'>
        <li className='text-lg md:text-xl'><span className='font-bold'>Duration:</span> 6 sessions</li>
        <li className='text-lg md:text-xl'><span className='font-bold'>Session Length:</span> 90 minutes</li>
        <li className='text-lg md:text-xl'><span className='font-bold'>Group Size:</span> Maximum of 7 participants</li>
        <li className='text-lg md:text-xl'><span className='font-bold'>Frequency:</span> Twice a Week for 3 Weeks</li>
      </ul>
    </div>

    

  </div>
</div>


        <div className='col-span-1 md:col-span-2 bg-[#F5E3CA] p-4 rounded-2xl object-shadow text-center'>
          <h2 className='font-medium text-[#C2763F] text-2xl md:text-3xl p-4'>Special Course</h2>
          <div className='flex flex-col md:flex-row'>
            <div className='w-full'>
              <h2 className='pl-4 text-3xl md:text-4xl kg-red-hands font-semibold text-black'>For English Major Students</h2>
              <p className='pl-8 py-4 text-lg md:text-xl font-medium text-[#4B2E19]'>
                For English language majors, we also offer specialized support in overcoming challenges in core areas of linguistics, including sociolinguistics, syntax, morphology, psycholinguistics, applied linguistics, phonology, and semantics.
              </p>
              <p className='pl-8 py-4 text-lg md:text-xl font-black text-black'>Coming Soon</p>
            </div>

          </div>
        </div>

        <div className='col-span-1 bg-[#F5E3CA] p-5 rounded-2xl object-shadow flex flex-col justify-center text-center'>
          <h2 className='text-3xl md:text-4xl kg-red-hands font-semibold text-black'>Tune in for Inspiration!</h2>
          <p className='text-[#4B2E19] text-xl md:text-2xl font-medium py-5'>Listen to our latest episodes on Spotify and let your journey begin</p>
          <button className="mx-auto btn bg-[#C2763F] text-[#F5E3CA] font-bold border-none px-6 py-3">
            <a href="https://open.spotify.com/show/7CgvQOXUN5lGbR4tMAriKq?si=LQUoTBvdTgKJaPaZnC98Xg&nd=1&dlsi=8b28dab7b0f849fb" target="_blank" rel="noopener noreferrer">
              Listen to Our Podcast <FontAwesomeIcon icon={faSpotify} size="lg" className='icon-hover pl-2' />
            </a>
          </button>
        </div>

        <div className='col-span-1 bg-[#F5E3CA] p-5 rounded-2xl object-shadow flex flex-col justify-center text-center'>
          <h2 className='text-3xl md:text-4xl kg-red-hands font-semibold text-black'>Ready to Enroll?</h2>
          <p className='text-[#4B2E19] text-xl md:text-2xl font-medium py-5'>Take the first step towards mastering English with confidence and ease</p>
          <Link to="/join-us">
          <button className="mx-auto btn btn-outline text-[#C2763F] font-bold border-[#C2763F] border-[3px] hover:bg-black hover:text-[#F5E3CA] hover:border-black">Join Us</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Courses;

import React, { useState } from 'react';

function ClientForm() {
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    setSubmitting(true); // Disable the button while submitting

    try {
      const response = await fetch(e.target.action, {
        method: e.target.method,
        body: formData,
      });

      const result = await response.json();

      if (result.result === 'success') {
        setSuccessMessage('Your message has been sent successfully! We will get back to you soon.');
        setErrorMessage('');
        e.target.reset();
      } else {
        setErrorMessage('There was an error sending your message. Please try again later.');
        setSuccessMessage('');
      }
    } catch (error) {
      setErrorMessage('Something went wrong. Please check your internet connection and try again.');
      setSuccessMessage('');
    } finally {
      setSubmitting(false); // Re-enable the button after submission
    }
  };

  return (
    <div className='bg-[#E7D3B7] min-h-screen p-5 md:p-10 flex justify-center items-center roboto-mono-700'>
   
      <div className='bg-[#F5E3CA] p-6 md:p-8 rounded-2xl object-shadow w-full max-w-lg'>
           {/* Course Price */}
    <div className='p-4'>
      <h2 className='text-3xl md:text-4xl font-semibold text-center text-[#C2763F]'>Course Price</h2>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
        {/* Within Egypt */}
        <div>
          <p className='text-[#4B2E19] text-xl md:text-2xl font-medium mb-3 pt-4 '>Within Egypt</p>
          <ul className='text-[#4B2E19] text-lg md:text-xl font-medium'>
            <li><span className='font-bold'>Placement Test:</span> 300 EGP</li>
            <li><span className='font-bold'>Group Course:</span> 1,800 EGP</li>
            <li><span className='font-bold'>Private Course:</span> 4,500 EGP</li>
          </ul>
        </div>

        {/* Outside Egypt */}
        <div>
          <p className='text-[#4B2E19] text-xl md:text-2xl font-medium mb-3 pt-4'>Outside Egypt</p>
          <ul className='text-[#4B2E19] text-lg md:text-xl font-medium'>
            <li><span className='font-bold'>Placement Test:</span>  $30</li>
            <li><span className='font-bold'>Group Course:</span> $85</li>
            <li><span className='font-bold'>Private Course:</span> $170</li>
          </ul>
        </div>
      </div>
    </div>
        <h3 className='font-medium text-[#C2763F] text-2xl md:text-3xl lg:text-4xl text-center mb-6'>Contact Us</h3>

        {/* Display Success or Error Message */}
        {successMessage && <div className='mb-4 text-green-600 font-semibold'>{successMessage}</div>}
        {errorMessage && <div className='mb-4 text-red-600 font-semibold'>{errorMessage}</div>}

        <form
          className='gform flex flex-col gap-4'
          id="contactForm"
          method="POST"
          data-email="mahmoudsaleh343@gmail.com"
          action="https://script.google.com/macros/s/AKfycbzJoNeZIn5S0hb4RxfejFvLVljpgMOb8OtCkW0F10p2ttMngY0hnbuFSV-ybC2WcWRojg/exec"
          onSubmit={handleSubmit}
        >
          {/* Existing Form Fields */}
          <label className='font-semibold text-[#4B2E19] text-lg md:text-xl' htmlFor="whatsapp">
            WhatsApp Number
            <input 
              type='tel' 
              id='whatsapp' 
              name='whatsapp' 
              required 
              className='mt-2 p-2 md:p-3 w-full bg-[#E7D3B7] border border-[#C2763F] rounded-lg text-black focus:outline-none focus:ring-2 focus:ring-[#C2763F]' 
              placeholder='+1234567890'
            />
          </label>

          <label className='font-semibold text-[#4B2E19] text-lg md:text-xl' htmlFor="name">
            Name
            <input 
              type='text' 
              id='name' 
              name='name' 
              required 
              className='mt-2 p-2 md:p-3 w-full bg-[#E7D3B7] border border-[#C2763F] rounded-lg text-black focus:outline-none focus:ring-2 focus:ring-[#C2763F]' 
              placeholder='Your Name'
            />
          </label>

          <label className='font-semibold text-[#4B2E19] text-lg md:text-xl' htmlFor="email">
            Email
            <input 
              type='email' 
              id='email' 
              name='email' 
              required 
              className='mt-2 p-2 md:p-3 w-full bg-[#E7D3B7] border border-[#C2763F] rounded-lg text-black focus:outline-none focus:ring-2 focus:ring-[#C2763F]' 
              placeholder='Your Email'
            />
          </label>

          <label className='font-semibold text-[#4B2E19] text-lg md:text-xl' htmlFor="age">
            Age
            <input 
              type='number' 
              id='age' 
              name='age' 
              required 
              className='mt-2 p-2 md:p-3 w-full bg-[#E7D3B7] border border-[#C2763F] rounded-lg text-black focus:outline-none focus:ring-2 focus:ring-[#C2763F]' 
              placeholder='Your Age'
            />
          </label>

          <label className='font-semibold text-[#4B2E19] text-lg md:text-xl' htmlFor="course">
            Course Selection
            <select 
              id='course' 
              name='course' 
              required 
              className='mt-2 p-2 md:p-3 w-full bg-[#E7D3B7] border border-[#C2763F] rounded-lg text-black focus:outline-none focus:ring-2 focus:ring-[#C2763F]'
            >
              <option value="phonetics">Phonetics Course</option>
              <option value="english-major" disabled>English Major Students Course</option>
            </select>
          </label>

          {/* New Schedule Options Field */}
          <label className='font-semibold text-[#4B2E19] text-lg md:text-xl' htmlFor="schedule">
            Schedule Options
            <select
              id='schedule'
              name='schedule'
              required
              className='mt-2 p-2 md:p-3 w-full bg-[#E7D3B7] border border-[#C2763F] rounded-lg text-black focus:outline-none focus:ring-2 focus:ring-[#C2763F]'
            >
              <option value="" disabled selected>Select your schedule</option>
              <option value="Saturday and Tuesday">Saturday and Tuesday</option>
              <option value="Sunday and Wednesday">Sunday and Wednesday</option>
              <option value="Monday and Thursday">Monday and Thursday</option>
            </select>
          </label>

          <button 
            type='submit' 
            className='mt-6 bg-[#C2763F] text-[#F5E3CA] font-bold p-2 md:p-3 rounded-lg w-full hover:bg-[#4B2E19] transition duration-300'
            disabled={submitting}
          >
            {submitting ? 'Sending...' : 'Send'}
          </button>
        </form>
        
      </div>
    </div>
  );
}

export default ClientForm;
